<template>
    <!-- 菜单权限管理 -->
    <div class="paddbox autobox">
        <div>
            <div>
                <el-card>
                <div>
            <div class="toutiaoAddModelTitleTop flex">
          <div>
            <el-button
              type="primary"
              :disabled="openSonviewAble"
              @click="upAccountFun()"
              >{{
                openSonviewAble == false ? '请选择子账户' : '已选择完成'
              }}</el-button
            >
          </div>
          <div>
            <div class="title">
              <el-tooltip
                class="item"
                effect="dark"
                :content="sonString"
                placement="right"
              >
                <div>已选:({{ accountIds.length }})</div>
              </el-tooltip>
            </div>
          </div>
          <!-- <div>
            <selectSonAccount
              :data="selectSonAccountData"
            ></selectSonAccount>
          </div> -->
          <div>
            <el-button type="danger" round @click="ResetPageFun()"
              >重置</el-button
            >
          </div>
        </div>
          </div>
          <!-- <el-divider></el-divider> -->
            </el-card>
            </div>
            <div>
                <el-card>
                    <div>
                        <el-form :label-position="right" label-width="150px">
                          <el-form-item label="创建落地页类型">
                            <el-radio-group v-model="cType">
                              <el-radio :label="'统一'">统一</el-radio>
                              <el-radio :label="'细分'">细分</el-radio>
                            </el-radio-group>
                          </el-form-item>
                          <el-form-item label="落地页名称" v-if="cType=='统一'">
                            <el-input v-model="tpageName" clearable style="width: 200px;"></el-input>
                          </el-form-item>
                          <el-form-item label="落地页标题" v-if="cType=='统一'">
                            <el-input v-model="tpageTitle" clearable style="width: 200px;"></el-input>
                          </el-form-item>
                          <el-form-item label="落地页图片" v-if="cType=='统一'">
                            <div v-if="timageUrl.length==0">
                          <!-- action="http://dfrt.natapp4.cc/v3/tencent/uploadTopImageSpec2" -->
                          <!-- action="https://wxappvue.df01.top/api/v3/tencent/uploadTopImageSpec2" -->
                          <!-- action="https://wxappvue.df01.top/api/v3/tencent/uploadTopImageSpec2" -->
                            <el-upload
                              class="avatar-uploader"
                              :headers="{ authorize: token }"
                              action="https://wxappvue.df01.top/api/v3/tencent/uploadTopImageSpec2"
                              :on-success="
                                (event, file, fileList) =>
                                  tonSuccess(event, file, fileList)
                              "
                              multiple
                              :show-file-list="false"
                              :limit="50"
                            >
                              <el-button
                                size="small"
                                type="success"
                                round
                                style="maring-right:20px"
                                >上传图片
                              </el-button>
                              <span>
                                 图片大小必须<span
                                  style="color:red"
                                  >在规定范围</span
                                ></span
                              >
                            </el-upload>
                          </div>
                            <div class="imglist" v-if="timageUrl.length > 0">
                              <div class="imglistItem">
                                <img
                                  :src="timageUrl"
                                  alt=""
                                  style="width: 100px;height: 100px;"
                                />
                                <div class="del_bg" @click="tdeleimg()">
                                  <i class="el-icon-delete"></i>
                                </div>
                              </div>
                            </div>
                         </el-form-item>
                         <el-form-item label="按钮文案1" >
                            <el-input v-model="btnTitle1" clearable style="width: 200px;"></el-input>
                         </el-form-item>
                         <el-form-item label="按钮文案2" >
                            <el-input v-model="btnTitle2" clearable style="width: 200px;"></el-input>
                         </el-form-item>
                         <el-form-item label="分享标题" >
                            <el-input v-model="shareTtle" clearable style="width: 200px;"></el-input>
                         </el-form-item>
                         <el-form-item label="分享描述" >
                            <el-input v-model="shareDescription" clearable style="width: 200px;"></el-input>
                         </el-form-item>
                        </el-form>                        
                    </div>
                    <!-- 表格 -->
                    <div>
                      <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                          prop="accountId"
                          label="账户id"
                          width="180">
                        </el-table-column>
                        <el-table-column
                        v-if="cType=='细分'"
                          label="落地页名称"
                          width="250">
                          <template #default="scope">
                            <el-input v-model="scope.row.pageName" clearable></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column
                        v-if="cType=='细分'"
                          label="落地页标题"
                          width="250">
                          <template #default="scope">
                            <el-input v-model="scope.row.pageTitle" clearable></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column
                        v-if="cType=='细分'"
                          label="落地页图片"
                          width="300">
                          <template #default="scope">
                            <div>
                              <div v-if="scope.row.imageUrl.length==0">
                          <!-- action="http://dfrt.natapp4.cc/v3/tencent/uploadTopImageSpec2" -->
                          <!-- action="https://wxappvue.df01.top/api/v3/tencent/uploadTopImageSpec2" -->
                          <!-- action="https://wxappvue.df01.top/api/v3/tencent/uploadTopImageSpec2" -->
                            <el-upload
                              class="avatar-uploader"
                              :headers="{ authorize: token }"
                              action="https://wxappvue.df01.top/api/v3/tencent/uploadTopImageSpec2"
                              :on-success="
                                (event, file, fileList) =>
                                  onSuccess(event, file, fileList,scope)
                              "
                              multiple
                              :show-file-list="false"
                              :limit="50"
                            >
                              <el-button
                                size="small"
                                type="success"
                                round
                                style="maring-right:20px"
                                >上传图片
                              </el-button>
                              <span>
                                 图片大小必须<span
                                  style="color:red"
                                  >在规定范围</span
                                ></span
                              >
                            </el-upload>
                          </div>
                            <div class="imglist" v-if="scope.row.imageUrl.length > 0">
                              <div class="imglistItem">
                                <img
                                  :src="scope.row.imageUrl"
                                  alt=""
                                  style="width: 100px;height: 100px;"
                                />
                                <div class="del_bg" @click="deleimg(scope)">
                                  <i class="el-icon-delete"></i>
                                </div>
                              </div>
                            </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          width="250"
                          label="小程序原始Id">
                          <template #default="scope">
                            <el-input v-model="scope.row.weappUsername" clearable></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column
                          width="300"
                          label="小程序路径">
                          <template #default="scope">
                            <el-input v-model="scope.row.weappPath" clearable></el-input>
                          </template>
                        </el-table-column>
                      </el-table>
                      
                    </div>
                </el-card>
            </div>
            <div>
              <el-card>
                <div class="btn flex">
                  <div><el-button type="primary"  @click="createTencentOfficalPagesFun">确定</el-button></div>
                  <div></div>
                </div>
              </el-card>
            </div>
        </div>

        <!-- 选择子账户弹窗 -->    
        <el-dialog v-model="openSonview">
      <insertViewMP
        @getAccountListFun="getSonAccountListFun"
        @getSelectSonAccountData="getSelectSonAccountData"
        @getCannelFun="getCannelFun"
      ></insertViewMP>
    </el-dialog>        

    </div>
  </template>
  
  <script>
  import { 
    uploadTopImageSpec2,
    createTencentOfficalPages
  } from '@/request/new/videoBatchManagement'
  import { ElMessage } from 'element-plus'
  import { inject, reactive, toRefs, watch } from 'vue'
  import insertViewMP from '@/components/batch/insertViewMP.vue'
  
  export default {
    //官方落地页
    name: 'wechatPagesOfficial',
    components: { insertViewMP },
    setup() {
      const state = reactive({
      // 获取用户token
      token: 'Bearer' + localStorage.getItem('token'),
      //创建类型
      cType:'统一',  
      //选中的子账户
      accountIds:[],
      // 子账户的字符串
      sonString: '',              
      // 打开选择子账户的弹窗
      openSonview: false,
      // 选择子账户的按钮是否可用
      openSonviewAble: false,
      //统一数据---落地页名称
      tpageName:'',
      //统一数据---落地页标题
      tpageTitle:'2025热门短剧',      
      //统一数据---落地页图片
      timageUrl:'',
      //统一数据---按钮文案1
      btnTitle1:'点击观看精彩全集',
      //统一数据---按钮文案2
      btnTitle2:'观看更多精彩内容',
      //统一数据---分享标题
      shareTtle:'热播短剧，精彩不断！',
      //统一数据---分享描述
      shareDescription:'点击观看精彩全集',
      //表格数据
      tableData:[],
      // 选择子账户的按钮值的变化
      openSonviewAbleFun: () => {
        state.openSonviewAble = !state.openSonviewAble
      },  
      // 修改打开选择子账户的弹窗
      upAccountFun: () => {
        state.openSonview = !state.openSonview
      },
      // 接收子账号组件的取消
      getCannelFun: () => {
        state.upAccountFun()
      },
      //接受子账号组件的确定
      getSonAccountListFun: (data) => {
        state.accountIds=data
        state.deleteData()
        
        data.forEach((item, index) => {
          if (data.length - 1 == index) {
            state.sonString += item
          } else {
            state.sonString += item + '，'
          }

          
        })        
        state.upAccountFun()
      },
      //清空数据
      deleteData:()=>{
        state.tpageName=''
        state.timageUrl=''
        state.btnTitle1='点击观看精彩全集'
        state.btnTitle2='观看更多精彩内容'
        state.shareTtle='热播短剧，精彩不断！'
        state.shareDescription='点击观看精彩全集'
        state.tpageTitle='2025热门短剧'


        state.tableData=[]
        state.accountIds.forEach((item)=>{
            //插入数据结构
            const datas ={
            accountId:item,
            pageName:'',
            imageUrl:'',
            weappUsername:'',
            weappPath:'',
            btnTitle1:'',
            btnTitle2:'',
            shareTtle:'',
            shareDescription:'',
            pageTitle:'',
          }
          state.tableData.push(datas)
        })
      },
      // 统一的上传图片成功
      tonSuccess: (response, z, x, indexs) => {
        state.timageUrl=response.data.url
      },    
      // 细分的上传图片成功
      onSuccess: (response, z, x, scope) => {
        // console.log(response,scope)
        state.tableData[scope.$index].imageUrl=response.data.url
      },
      //统一的删除图片
      tdeleimg:()=>{
        state.timageUrl=''
      },
      //细分的删除图片
      deleimg:(scope)=>{
        state.tableData[scope.$index].imageUrl=''
      },
      //重置
      ResetPageFun:()=>{
        location.reload()
      },
      //创建官方落地页
      createTencentOfficalPagesFun:()=>{
        if(state.cType=='统一'){
          if(state.tpageName==''||state.timageUrl==''||state.btnTitle1==''||state.btnTitle2==''||state.shareTtle==''||state.shareDescription==''||state.tpageTitle==''){
          return   ElMessage({
          type: 'error',
          message: '统一数据不能为空',
        })
          }
          state.tableData.forEach((item)=>{
            item.pageName=state.tpageName
            item.imageUrl=state.timageUrl
            item.btnTitle1=state.btnTitle1
            item.btnTitle2=state.btnTitle2
            item.shareTtle=state.shareTtle
            item.shareDescription=state.shareDescription
            item.pageTitle=state.tpageTitle
          })  
        }else{
          //细分赋值
          state.tableData.forEach((item)=>{
            item.btnTitle1=state.btnTitle1
            item.btnTitle2=state.btnTitle2
            item.shareTtle=state.shareTtle
            item.shareDescription=state.shareDescription
          }) 
        }

        //效验整体
        for( const item of state.tableData){
            if(item.pageName==''||item.imageUrl==''||item.weappUsername==''||item.weappPath==''||item.btnTitle1==''||item.btnTitle2==''||item.shareTtle==''||item.shareDescription==''||item.pageTitle==''){
           return   ElMessage({
           type: 'error',
           message: '数据不能为空', })
            }
          }
          const datas ={
            accountIds:state.accountIds,
            wechatPagesDtos:state.tableData
          }
          createTencentOfficalPages(datas).then((res)=>{
            ElMessage({
           type: 'success',
           message: '创建成功', })
            state.deleteData()
          })


      }      
      })
 
  

      return {
        // 结构赋值，暴露出去就不用 state.xx
        ...toRefs(state),

      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
    .toutiaoAddModelTitleTop {
    > div {
    margin-right: 10px;
    }
    }
    .del_bg {
      width: 100%;
      height: 100px;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      text-align: center;
      padding-top: 30px;
      font-size: 18px;
      transition: 1s;
      display: none;
    }
    .imglistItem {
      margin-top: 20px;
      display: flex;
      width: 100px;
      height: 100px;
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      cursor: pointer;
      margin-right: 20px;
    }
    .imglistItem:hover {
      .del_bg {
        display: block;
      }
    }
    .imglist {
      display: flex;
      width: 700px;
      flex-wrap: wrap;
    }
    .btn {
    justify-content: flex-end;
    padding: 10px 10px 0px 15px;
    > div {
      padding-left: 30px;
    }
    }

  </style>
  